<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("customerLoanPurpose.sector")
                }}</label>
                <select
                    v-model="model.sector_id"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('sector_id') }"
                >
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                        >{{ $t("select") }}</option
                    >
                    <option
                        v-for="(sector, index) in loanSectors"
                        :key="index"
                        :value="sector.id"
                        >{{
                            locale == "kh" ? sector.sector_kh : sector.sector_en
                        }}</option
                    >
                </select>
                <div class="invalid-feedback" v-if="errors.has('sector_id')">
                    {{ errors.first("sector_id") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label">{{
                    $t('customerLoanPurpose.purposeCode')
                }}</label>
                <input
                    v-model.trim="model.purpose_code"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('purpose_code') }"
                    ref="purpose_code"
                />
                <div class="invalid-feedback" v-if="errors.has('purpose_code')">
                    {{ errors.first('purpose_code') }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t('customerLoanPurpose.purposeEn')
                }}</label>
                <input
                    v-model.trim="model.purpose_en"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('purpose_en') }"
                    ref="purpose_en"
                />
                <div class="invalid-feedback" v-if="errors.has('purpose_en')">
                    {{ errors.first('purpose_en') }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label">{{
                    $t('customerLoanPurpose.purposeKh')
                }}</label>
                <input
                    v-model.trim="model.purpose_kh"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.has('purpose_kh') }"
                />
                <div class="invalid-feedback" v-if="errors.has('purpose_kh')">
                    {{ errors.first('purpose_kh') }}
                </div>
            </div>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t('cancel') }}</ts-button
                >
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t('saveAddNew') }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSave"
                >
                    {{ $t('save') }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                >
                    {{ $t('update') }}</ts-button
                >
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'CustomerLoanPurpose',
    data () {
        return {
            errors: new Errors(),
            waiting: false,
            waiting_new: false,
            loading: false,
            model: {
                sector_id: null,
                purpose_code: null,
                purpose_en: null,
                purpose_kh: null
            }
        }
    },
    computed: {
        ...mapState('creditOperation/customerLoanPurpose', ['edit_data', 'loanSectors']),
        isUpdate () {
            return !isEmpty(this.edit_data)
        },
        locale() {
            return this.$root.$i18n.locale;
        }
    },
    methods: {
        ...mapActions("creditOperation/customerLoanPurpose", ["getLoanSector"]),
        async fetchResource() {
            this.loading = true;
            await this.getLoanSector();
            this.setEditData();
            this.loading = false;
        },
        async onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch(
                    'creditOperation/customerLoanPurpose/store',
                    this.model
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('fetchData')
                    this.clearInput()
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        async onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch(
                    'creditOperation/customerLoanPurpose/store',
                    this.model
                )
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('creditOperation/customerLoanPurpose/update', {
                    id: this.edit_data.id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        setEditData () {
            this.$nextTick(function () {
                this.$refs.purpose_code.focus()
            })
            if (!isEmpty(this.edit_data)) {
                this.model.sector_id = this.edit_data.sector_id
                this.model.purpose_code = this.edit_data.purpose_code
                this.model.purpose_en = this.edit_data.purpose_en
                this.model.purpose_kh = this.edit_data.purpose_kh
            }
        },
        clearInput () {
            this.errors = new Errors()
            this.model.sector_id = null
            this.model.purpose_code = null
            this.model.purpose_en = null
            this.model.purpose_kh = null
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'Customer Loan Purpose',
                desc: not.text
            })
        }
    }
}
</script>
